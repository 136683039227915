import React from 'react';
import './nosotros.css';

function Nosotros() {
  return (<div className="nosotros" id="nosotros">
    <div className="nosotros-title">
      <h1>
        NOSOTROS
      </h1>
    </div>
    <p>
      Somos una empresa dedicada a la integración tecnológica, desarrollando las
      mejores soluciones en tecnología para la seguridad, de usted, su empresa y
      empleados. Contamos con profesionales ampliamente capacitados y
      certificados para brindar las mejores enmiendes de acuerdo a su necesidad.
    </p>
  </div>);
}

export default Nosotros;
