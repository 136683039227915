import React                   from 'react';
import './footer.css';
import { NavHashLink as Link } from 'react-router-hash-link';
import Logo                    from '../../assets/transdigitalLogoWhite@2x.png';
import MenuItems               from '../NavBar/Menu';

const menu = [
  {
    url: '#nosotros',
    name: 'Nosotros',
  }, {
    url: '#hacemos',
    name: 'Que hacemos',
  }, {
    url: '#somos',
    name: 'Quienes Somos',
  }, {
    url: '#contacto',
    name: 'Contacto',
  },
];

const FuncScroll = (scrollId) => {
  const heightScroll = document.getElementById(scrollId.substring(1)).offsetTop;
  window.scroll({
    top: heightScroll - 119,
    left: 0,
    behavior: 'smooth',
  });
};

function Footer() {

  return (<div className="footer">
    <nav>
      <div>
        <div className="navbar-center">
          <div>
            <Link to="#landing" scroll={ () => FuncScroll('#landing') }>
              <img src={ Logo } alt="Logo" className="logo" />
            </Link>
          </div>
          <ul className="menu-custom green-hover footer-bar">

            { menu.map((value, index) => (<MenuItems
              key={ index }
              name={ value.name }
              icon={ value.icon }
              url={ value.url }
              submenu={ value.submenu }
              component={ value.component }
            />)) }
          </ul>
          <div className="copyright">2019. TransDigital. All Rights Reserved
          </div>
        </div>
      </div>
    </nav>
  </div>);
}

export default Footer;
