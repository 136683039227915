import React        from 'react';
import './contacto.css';
import LocationIcon from '../../assets/locationIcon@2x.png';
import EmailIcon    from '../../assets/emailIcon@2x.png';
import PhoneIcon    from '../../assets/phoneIcon@2x.png';

const detailsContacto = [
  {
    icon: LocationIcon,
    title: 'Dirección',
    detail: 'Gonazalo bulnes No 186, esquina Guido spano',
  }, {
    icon: EmailIcon,
    title: 'Email',
    detail: 'info@transdigitalweb.com',
  }, {
    icon: PhoneIcon,
    title: 'Teléfono',
    detail: '592 021665195',
  },
];

const ItemContacto = ({ icon, title, detail }) => (
  <div className="item-contacto">
    <div className="icon-container">
      <img src={ icon } alt="" />
    </div>
    <div className="details-container">
      <h2 className="item-contacto-title">{ title }</h2>
      <div className="item-contacto-detail">
        { detail }
      </div>
    </div>
  </div>);

function Contacto() {
  return (<div className="contacto" id="contacto">
    <div className="contacto-title">
      <h1 className="">
        CONTACTO
      </h1>
    </div>
    <section>
      <div className="info-contacto">
        { detailsContacto.map(
          (item) => <ItemContacto icon={ item.icon } title={ item.title }
                                  detail={ item.detail }
                                  key={ item.title } />) }
      </div>
      <form action="" className="formulario-contacto">
        <div>
          <section>
            <label htmlFor="email">Email</label>
            <input type="email" />
          </section>
          <section>
            <label htmlFor="nombre">Nombre</label>
            <input type="nombre" />
          </section>
        </div>
        <div>
          <section>
            <label htmlFor="mensaje">Mensaje</label>
            <textarea name="mensaje" id="mensaje" cols="90" rows="3" />
          </section>
        </div>
        <button>Enviar</button>
      </form>
    </section>
  </div>);
}

export default Contacto;
