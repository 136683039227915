import React, { Component }    from 'react';
import MenuItems               from './Menu';
import Logo                    from '../../assets/transdigitalLogocolor@3x.png';
import { NavHashLink as Link } from 'react-router-hash-link';
import './menu.css';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.handleNavbar = this.handleNavbar.bind(this);
  }

  listenScrollEvent = e => {
    if (document.getElementById('transparent') !== null) {
      if (window.scrollY <= 102) {
        document.getElementById('transparent')
                .classList
                .add('transparent');
      } else {
        document.getElementById('transparent')
                .classList
                .remove('transparent');
      }
    }
  };

  componentDidMount() {
    // window.addEventListener('scroll', this.listenScrollEvent);
  }

  FuncScroll = (scrollId) => {
    const heightScroll = document.getElementById(
      scrollId.substring(1)).offsetTop;
    window.scroll({
      top: heightScroll - 119,
      left: 0,
      behavior: 'smooth',
    });
  };

  handleNavbar() {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('#' + burger.dataset.target);
    burger.classList.toggle('is-active');
    nav.classList.toggle('is-active');
  }

  render() {
    const menu = [
      {
        url: '#nosotros',
        name: 'Nosotros',
      }, {
        url: '#hacemos',
        name: 'Que hacemos',
      }, {
        url: '#somos',
        name: 'Quienes Somos',
      }, {
        url: '#contacto',
        name: 'Contacto',
      },
    ];
    return (<header className="scroll">
      <nav className="navbar" role="navigation"
           aria-label="main navigation">
        <div className="navbar-brand">
          <div className="">
            <Link smooth to="#landing"
                  scroll={ () => this.FuncScroll('#landing') }>
              <img src={ Logo } alt="Logo" className="logo" />
            </Link>
          </div>
          <span role="button" className="navbar-burger burger"
                aria-label="menu" aria-expanded="false"
                data-target="navbarBasic"
                onClick={ this.handleNavbar }>
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
        </span>
        </div>
        <div id="navbarBasic" className="navbar-menu">
          <div className="navbar-center">
            <ul className="menu-custom green-hover level-item">

              { menu.map((value, index) => (<MenuItems
                key={ index }
                name={ value.name }
                icon={ value.icon }
                url={ value.url }
                submenu={ value.submenu }
                component={ value.component }
                scrollId={ value.url }
              />)) }
            </ul>
          </div>
        </div>
      </nav>
    </header>);
  }
}

export default NavBar;
