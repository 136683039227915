import React from 'react';
import './somos.css';

const somosItemsProperties = [
  {
    title: 'Misión',
    paragraph: `Proporcionar la seguridad de nuestros clientes a través de nuestras herramientas de tecnología, utilizando las más altos estándares de calidad y confidencialidad.`,
  }, {
    title: 'Objetivos',
    paragraph: `Brindar servicios de seguridad electrónica como también de transacciones digitales, además de una solución integral y efectiva a las necesidades de nuestros clientes, asesorándolos técnicamente a través del conocimiento de las más altas tecnologías existentes en
el mercado.`,
  },
];

const SomosItem = ({ title, paragraph }) => (<div className="somos-item">
  <h3 className="somos-item-title">
    { title }
  </h3>
  <p className="somos-item-paragraph">
    { paragraph }
  </p>
</div>);

function Somos() {
  return (<div className="somos" id="somos">
    <div className="title">
      <h1 className="">
        QUIENES SOMOS
      </h1>
    </div>
    <section className="somos-items">
      { somosItemsProperties.map(
        (item) => (<SomosItem paragraph={ item.paragraph }
                              title={ item.title } key={ item.title } />)) }
      <div className="presidente-item">
        <div>
          <h3>
            Monica Cabrera Figueredo
          </h3>
          Presidente
        </div>
      </div>
    </section>
  </div>);
}

export default Somos;
