import React from 'react';
import './landing.css';

function Landing() {
  return (<div className="landing" id="landing">
    <div className="landing-rectangle">
      <h1 className="">
        Brindamos soluciones digitales
      </h1>
      <p className="">
        Unimos la industria de pagos con la tecnología, para conformar un equipo
        preparado para cualquier desafío.
      </p>
    </div>
  </div>);
}

export default Landing;
