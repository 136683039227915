import React                   from 'react';
import { NavHashLink as Link } from 'react-router-hash-link';
import './styles.css';

class MenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.FuncScroll = this.FuncScroll.bind(this);
  }

  FuncScroll = (scrollId) => {
    const heightScroll = document.getElementById(
      scrollId.substring(1)).offsetTop;
    window.scroll({
      top: heightScroll - 119,
      left: 0,
      behavior: 'smooth',
    });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }

  render() {
    const {
      pathname, url, name, submenu, component,
    } = this.props;
    let activeSubmenu = false;
    let iconMenu = '';
    if (submenu !== undefined) {
      iconMenu = <i className="icon fa fa-angle-down" aria-hidden="true" />;
      const cantSubMenu = submenu.items.length;
      for (let i = 0; i < cantSubMenu; i++) {
        if (pathname === submenu.items[i].url) {
          activeSubmenu = true;
        }
      }
    }
    return (<li
      className={ `${ pathname === url || activeSubmenu ? 'active ' : '' }` }
      onClick={ this.showDropdownMenu }
    >
      { url && (<Link smooth to={ url } scroll={ () => this.FuncScroll(url) }>
        <span>{ name }</span>
      </Link>) }
      { url === '' && (<a href="#" onClick={ () => this.FuncScroll(url) }>
        <span>{ name }</span>
        { iconMenu }
      </a>) }
      { component && (component) }
    </li>);
  }
}

export default MenuItems;
