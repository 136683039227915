import React    from 'react';
import './App.css';
import NavBar   from './Sections/NavBar';
import Landing  from './Sections/Landing';
import Nosotros from './Sections/Nosotros';
import Hacemos  from './Sections/Hacemos';
import Somos    from './Sections/Somos';
import Contacto from './Sections/Contacto';
import Footer   from './Sections/Footer';

import './css/main.css';

function App() {
  return (<div className="App">
    <NavBar />
    <Landing />
    <Nosotros />
    <Hacemos />
    <Somos />
    <Contacto />
    <Footer />
  </div>);
}

export default App;
