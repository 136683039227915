import React from 'react';
import './hacemos.css';

const hacemosItemsProperties = [
  {
    number: '01',
    title: 'Consultoría',
    paragraph: `Nuestro objetivo es ayudar a las empresas a lograr su mejor performance mediante el asesoramiento, impulsándolas a mejorar su actividad y la gestión en el día día. Ayudamos a crear propuestas de valor que alineen a los objetivos de negocios con la revolución digital que
vive la sociedad.`,
  }, {
    number: '02',
    title: 'Tecnología',
    paragraph: `La experiencia se esta convirtiendo en fuerza fundamental de una trasformación digital cada vez mas relacionada con personas. Nuestra estrategia está basada en identificar las aspiraciones, necesidades y deseos de nuestros clientes y así, ser capaces de construir productos digitales que sean eficientes y permanezcan activos en el tiempo.`,
  }, {
    number: '03',
    title: 'Operaciones',
    paragraph: `Ofrecemos soluciones basadas en productos y servicios que garantizan la renovación, trabajamos con el objetivo común para cada uno de nuestros clientes, a partir del mayor de los compromisos trasformando su cultura digital y logrando ventajas competitivas que les permita destacarse como empresa en el siglo xxi.`,
  },
];

const HacemosItem = ({ number, title, paragraph }) => (
  <div className="hacemos-item">
    <div className="hacemos-item-number">
      { number }
    </div>
    <h3 className="hacemos-item-title">
      { title }
    </h3>
    <p className="hacemos-item-paragraph">
      { paragraph }
    </p>
  </div>);

function Hacemos() {
  return (<div className="hacemos" id="hacemos">
    <div className="title">
      <h1 className="">
        QUE HACEMOS
      </h1>
    </div>
    <section className="hacemos-items">
      { hacemosItemsProperties.map((item) => (
        <HacemosItem number={ item.number } paragraph={ item.paragraph }
                     title={ item.title } key={ item.number } />)) }
    </section>
  </div>);
}

export default Hacemos;
